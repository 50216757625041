(function () {
  $(".inner-list-arrow").on("click", function (e) {
    e.preventDefault();
    $(this).closest('[data-inner-list]').toggleClass('list-open').find('.checkbox-list-internal').eq(0).slideToggle();
  });
  $('.clean-js').on('click', function(e){
    e.preventDefault();
    var $form = $(this).closest('form');
    $form.find('input:checked').prop('checked', false);
    $form.find('select').val('').trigger('change');
    $form.find('.group-input input').val('')
  })
})();
