(function () {
  $(".popup-btn").on("click", function () {
    var popupBlock = $("#" + $(this).data("popup"));
    popupBlock.addClass("active");
  });
  $(".popup-close").on("click", function () {
    var popupBlock = $(this).closest(".popup");
    popupBlock
      .css("opacity", "0")
      .find(".popup-content")
      .css("margin-top", "350px");
    setTimeout(function () {
      $(".popup").removeClass("active");
      popupBlock
        .css("opacity", "")
        .find(".popup-content")
        .css("margin-top", "");
    }, 600);
  });
})();
