(function () {
  $(".property-slider").slick({
    prevArrow: '<span class="icon-arrow-left"></span>',
    nextArrow: '<span class="icon-arrow-right"></span>',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
  });
})();
